import React from 'react';
import styled from 'styled-components';
import { Link, StaticQuery, graphql } from 'gatsby';
import ChevronIcon from '../images/svgs/chevron.svg';
import LogoIcon from '../images/svgs/logo-white.svg';
import ThreeBox from '../images/svgs/logo-white-bg.svg';

const Footer = () => {
  const StyledFooter = styled.footer`
    background: #1a1a1a;
    padding: 5rem 0;
    color: #ffffff;
    .container {
      display: flex;
      justify-content: space-between;
      .block {
        &.logo {
          width: 35%;
          p {
            text-align: justify;
          }
        }
        &.links {
          width: 15%;
        }
        &.contact {
          width: 50%;
          .two-rows {
            display: flex;
          }
        }
        .info-block {
          h3 {
            font-size: 1.8rem;
            margin-bottom: 0;
          }
          h4 {
            font-size: 1.4rem;
            margin: 0;
            color: ${props => props.theme.colors.org};
            font-family: ${props => props.theme.fonts.sec};
          }
          .branch {
            margin-top: 2rem;
          }
        }
        h2 {
          font-size: 2.5rem;
          margin-bottom: 0.8rem;
          letter-spacing: 0.5rem;
          color: ${props => props.theme.colors.org};
        }
        ul {
          display: flex;
          flex-direction: column;
          list-style: none;
          li {
            margin-bottom: 0.5rem;
            font-size: 1.4rem;
            a {
              color: ${props => props.theme.colors.grey};
              text-decoration: none;
              display: flex;
              align-items: flex-end;
              svg {
                width: 1.5rem;
                height: auto;
                fill: ${props => props.theme.colors.grey};
              }
              &:hover {
                color: #ffffff;
                svg {
                  fill: #ffffff;
                }
              }
            }
          }
        }
        svg {
          width: 12rem;
          height: auto;
          margin: 2rem 0 0;
        }
        p {
          width: 80%;
          font-size: 1.5rem;
          font-family: ${props => props.theme.fonts.sec};
          line-height: 1.8rem;
          color: ${props => props.theme.colors.grey};
        }
        .number p {
          width: 100%;
        }
      }
    }
    @media (max-width: 768px) {
      .container {
        flex-wrap: wrap;
        width: 65%;
        margin: auto;
        .block.contact .two-rows {
          justify-content: center;
          margin-top: 3rem;
        }
        .logo,
        .links,
        .contact {
          width: 100% !important;
          text-align: center;
          margin-bottom: 3rem;
          p {
            width: 100%;
            text-align: center;
          }
          ul li a {
            justify-content: center;
          }
        }
      }
    }
    @media (max-width: 450px) {
      .block.contact .two-rows {
        flex-direction: column;
      }
    }
  `;
  const StyledBottomFooter = styled.div`
    background: #101010;
    padding: 1rem 0;
    color: #ffffff;
    font-size: 1.6rem;
    font-family: ${props => props.theme.fonts.sec};
    .container {
      display: flex;
      justify-content: space-between;
      p {
        display: flex;
        svg {
          width: 1.8rem;
          height: auto;
          margin-left: 0.3rem;
        }
        a {
          margin: 0 0.5rem;
          color: red;
          text-decoration: none;
          display: flex;
        }
      }
    }
    @media (max-width: 768px) {
      text-align: center;
    }
    @media (max-width: 450px) {
      .container {
        flex-direction: column;
        align-items: center;
      }
    }
  `;
  return (
    <StaticQuery
      query={graphql`
        {
          wordpressAcfCompnayDetails {
            acf {
              need_help_text
              phone_number
              egypt_phone_number
              egypt_address
              address
              egypt_address
              company_email
              company_email_2
            }
          }
        }
      `}
      render={data => (
        <>
          <StyledFooter>
            <div className="container">
              <div className="block logo">
                <h2>ABOUT US</h2>
                <LogoIcon />
                <p>{data.wordpressAcfCompnayDetails.acf.need_help_text}</p>
              </div>
              <div className="block links">
                <h2>LINKS</h2>
                <ul>
                  <li>
                    <Link to="/">
                      <ChevronIcon /> Home
                    </Link>
                  </li>
                  <li>
                    <Link to="/about-us">
                      <ChevronIcon /> About Us
                    </Link>
                  </li>
                  <li>
                    <Link to="/services">
                      <ChevronIcon /> Services
                    </Link>
                  </li>
                  <li>
                    <Link to="/projects">
                      <ChevronIcon /> Projects
                    </Link>
                  </li>
                  <li>
                    <Link to="/news">
                      <ChevronIcon /> News
                    </Link>
                  </li>
                  <li>
                    <Link to="/partners">
                      <ChevronIcon /> Partners
                    </Link>
                  </li>
                  <li>
                    <Link to="/contact">
                      <ChevronIcon /> Contact
                    </Link>
                  </li>
                </ul>
              </div>
              <div className="block contact">
                <h2>CONTACT INFO</h2>
                <div className="two-rows">
                  <div className="info-block">
                    <h3>Address.</h3>
                    <div className="branch">
                      <h4>Main Address</h4>
                      <p>{data.wordpressAcfCompnayDetails.acf.address}</p>
                    </div>
                    <div className="branch">
                      <h4>Egypt Branch</h4>
                      <p>{data.wordpressAcfCompnayDetails.acf.egypt_address}</p>
                    </div>
                    <div className="branch">
                      <h4>Email</h4>
                      <p>
                        - {data.wordpressAcfCompnayDetails.acf.company_email}
                      </p>
                      <p>
                        - {data.wordpressAcfCompnayDetails.acf.company_email_2}
                      </p>
                    </div>
                  </div>
                  <div className="info">
                    <div className="info-block number">
                      <h3>Phone.</h3>
                      <div className="branch">
                        <h4>Ksa</h4>
                        <p>
                          {data.wordpressAcfCompnayDetails.acf.phone_number}
                        </p>
                      </div>
                      <div className="branch">
                        <h4>Egypt</h4>
                        <p>
                          {
                            data.wordpressAcfCompnayDetails.acf
                              .egypt_phone_number
                          }
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </StyledFooter>
          <StyledBottomFooter>
            <div className="container">
              <p>Copyright © 2019. All Rights Reserved.</p>
              <p>
                Created by{' '}
                <a
                  href="https://3box-advertising.com"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  3BOX <ThreeBox />
                </a>
              </p>
            </div>
          </StyledBottomFooter>
        </>
      )}
    />
  );
};

export default Footer;
