import { Link, StaticQuery, graphql } from 'gatsby';
import React from 'react';
import styled from 'styled-components';
import EmailIcon from '../images/svgs/email.svg';
import Logo from '../images/logo-color.png';
import MenuIcon from '../images/svgs/menu.svg';
import ExitIcon from '../images/svgs/exit.svg';

const Menu = () => {
  const openMenu = () => {
    document.getElementById('styled-mobile-menu').classList.add('menu-is-open');
  };

  const closeMenu = () => {
    document
      .getElementById('styled-mobile-menu')
      .classList.remove('menu-is-open');
  };

  const StyledMenu = styled.header`
    background: ${props => props.theme.colors.org};
    .container {
      height: 100%;
    }
    .logo {
      position: absolute;
      left: 0;
      background: #ffffff;
      height: calc(100% + 3.5rem);
      width: 13rem;
      .logo-b {
        height: 100%;
        display: flex;
        img {
          width: 80%;
          margin: auto;
        }
      }
    }
    .mob-menu {
      display: none;
      position: absolute;
      right: 0;
      top: 0.5rem;
      padding: 1.5rem 2rem;
      border: 0;
      background: transparent;
      svg {
        width: 4.5rem;
        height: auto;
        fill: white;
      }
    }
    .menu {
      display: flex;
      justify-content: flex-end;
      list-style: none;
      padding: 2rem 0;
      li {
        .current-page {
          background: #ffffff;
          color: ${props => props.theme.colors.org};
          padding: 1.5rem 2rem;
          margin-right: 1rem;
        }
        a {
          text-decoration: none;
          color: #ffffff;
          font-size: 1.2rem;
          padding: 1.5rem;
          display: inline-block;
          &:hover {
            color: #333333;
          }
        }
      }
    }
    .menu-bottom {
      height: 3.5rem;
      background: ${props => props.theme.colors.grey2};
      .container {
        .info-blocks {
          margin-left: 15rem;
          display: flex;
          align-items: center;
          height: 100%;
          .info {
            color: #ffffff;
            font-family: ${props => props.theme.fonts.sec};
            font-size: 1.4rem;
            margin-right: 1.5rem;
            display: flex;
            align-items: center;
            svg {
              width: 1.7rem;
              margin-right: 1rem;
            }
            &:first-child {
              svg {
                width: 2.2rem;
              }
            }
          }
        }
      }
    }
    @media (max-width: 768px) {
      .mob-menu {
        display: block;
      }
      .menu {
        padding: 4rem;
        li {
          display: none;
        }
      }
      .menu-bottom .container .info-blocks .info {
        display: none;
      }
    }
  `;

  const StyledMobMenu = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100%;
    background: rgba(27, 27, 27, 0.9);
    transition: 0.3s transform;
    transform: translate3d(100%, 0, 0);
    z-index: 100;
    button {
      position: absolute;
      top: 5rem;
      right: 3rem;
      background: transparent;
      padding: 1rem;
      border: 0;
      svg {
        width: 3rem;
        height: auto;
        fill: white;
      }
    }
    ul {
      display: flex;
      flex-direction: column;
      height: 100%;
      justify-content: center;
      list-style: none;
      padding: 0;
      margin-left: 10%;
      li {
        margin-bottom: 3rem;
        a {
          font-size: 1.8rem;
          text-decoration: none;
          font-family: ${props => props.theme.fonts.cairo};
          color: white;
        }
        .current-page {
          color: #ffffff;
          background: ${props => props.theme.colors.org};
          padding: 1rem 2rem;
          font-weight: bold;
        }
      }
    }
  `;
  return (
    <StaticQuery
      query={graphql`
        {
          wordpressAcfCompnayDetails {
            acf {
              company_email
            }
          }
        }
      `}
      render={data => (
        <>
          <StyledMenu>
            <div className="container">
              <div className="logo">
                <Link to="/">
                  <div className="logo-b">
                    <img src={Logo} alt="logo" />
                  </div>
                </Link>
              </div>
              <ul className="menu">
                <li>
                  <Link activeClassName="current-page" to="/">
                    HOME
                  </Link>
                </li>
                <li>
                  <Link activeClassName="current-page" to="/about-us">
                    ABOUT US
                  </Link>
                </li>
                <li>
                  <Link activeClassName="current-page" to="/services">
                    SERVICES
                  </Link>
                </li>
                <li>
                  <Link activeClassName="current-page" to="/projects">
                    PROJECTS
                  </Link>
                </li>
                <li>
                  <Link activeClassName="current-page" to="/news">
                    NEWS
                  </Link>
                </li>
                <li>
                  <Link activeClassName="current-page" to="/partners">
                    PARTNERS
                  </Link>
                </li>
                <li>
                  <Link activeClassName="current-page" to="/contact">
                    CONTACT
                  </Link>
                </li>
              </ul>
              <button onClick={openMenu} className="mob-menu">
                <MenuIcon />
              </button>
            </div>
            <div className="menu-bottom">
              <div className="container">
                <div className="info-blocks">
                  <div className="info email">
                    <EmailIcon />
                    {data.wordpressAcfCompnayDetails.acf.company_email}
                  </div>
                </div>
              </div>
            </div>
          </StyledMenu>
          <StyledMobMenu id="styled-mobile-menu">
            <button onClick={closeMenu} className="close-menu-button">
              <ExitIcon />
            </button>
            <ul className="menu">
              <li>
                <Link activeClassName="current-page" to="/">
                  HOME
                </Link>
              </li>
              <li>
                <Link activeClassName="current-page" to="/about-us">
                  ABOUT US
                </Link>
              </li>
              <li>
                <Link activeClassName="current-page" to="/services">
                  SERVICES
                </Link>
              </li>
              <li>
                <Link activeClassName="current-page" to="/projects">
                  PROJECTS
                </Link>
              </li>
              <li>
                <Link activeClassName="current-page" to="/news">
                  NEWS
                </Link>
              </li>
              <li>
                <Link activeClassName="current-page" to="/partners">
                  PARTNERS
                </Link>
              </li>
              <li>
                <Link activeClassName="current-page" to="/contact">
                  CONTACT
                </Link>
              </li>
            </ul>
          </StyledMobMenu>
        </>
      )}
    />
  );
};

export default Menu;
