import React from 'react';
import styled from 'styled-components';
import ChevUp from '../images/svgs/chevron-down.svg';

const GoUp = () => {
  const StyledGoUp = styled.a`
    position: fixed;
    bottom: 6.5rem;
    right: 2.6rem;
    padding: 1.2rem;
    border-radius: 50%;
    text-decoration: none;
    font-size: 4rem;
    color: #ffffff;
    display: flex;
    z-index: 5;
    background: ${props => props.theme.colors.org};
    -webkit-box-shadow: 0px 19px 23px -15px rgba(82, 82, 82, 1);
    -moz-box-shadow: 0px 19px 23px -15px rgba(82, 82, 82, 1);
    box-shadow: 0px 19px 23px -15px rgba(82, 82, 82, 1);
    transition: 0.3s background;
    cursor: pointer;
    svg {
      transform: rotate(180deg);
      width: 3.5rem;
      height: auto;
      fill: white;
    }
    &:hover {
      background: #222222;
    }
  `;

  let hrefLink = '#';

  return (
    <StyledGoUp href={hrefLink}>
      <ChevUp />
    </StyledGoUp>
  );
};

export default GoUp;
