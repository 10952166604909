import PropTypes from 'prop-types';
import React from 'react';
import { ThemeProvider } from 'styled-components';
import GlobalStyle from '../styles/global';
import theme from '../styles/theme';
import Menu from './Menu';
import Footer from './Footer';
import GoUp from './GoUp';

const Layout = ({ children }) => {
  return (
    <>
      <ThemeProvider theme={theme}>
        <Menu />
        <GoUp />
        <main>{children}</main>
        <Footer />
      </ThemeProvider>
      <GlobalStyle />
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired
};

export default Layout;
