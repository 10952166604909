const theme = {
  fonts: {
    pri: 'Montserrat, sans-serif',
    sec: 'Source Sans Pro, sans-serif'
  },
  responsive: {
    mw: '1200px'
  },
  colors: {
    org: '#FF7700',
    org2: '#A54E01',
    grey: '#9C9D9F',
    grey2: '#737475'
  }
};

export default theme;
